import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import { Cancel } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import LoadCountries from "components/services/LoadCountries";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function NewStates(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [returnLogin, setReturnLogin] = React.useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    name: "",
    countriesData: "",
    countries: "",
    errorName: false,
    errorCountries: false,
  });

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false);

    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
    });
    setOpenAlert(false);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");
    setopenAlertSuccess(false);

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true });
      setMensaje('El campo "Nombre" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.countries === "") {
      setValues({ ...values, errorCountries: true });
      setMensaje("Seleccione un país.");
      setOpenAlert(true);
      setError("error");
    } else {
      const dataValue = {
        name: values.name,
        countryId: values.countries,
      };

      axios
        .post(`${urlServices}states`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          setopenAlertSuccess(true);
          setmensaje_success("Registro guardado con éxito.");
          props.callBackRefresh();

          setValues({
            name: "",
            countriesData: "",
            countries: "",
          });
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 200);
          } else {
            setMensaje("Error de conexión, intente más tarde.");
            setOpenAlert(true);
            setError("error");
          }
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changeCountries = (data) => {
    setValues({
      ...values,
      countries: data !== null ? data.id : "",
      countriesData: data !== null ? data : "",
      errorCountries: false,
    });

    setOpenAlert(false);
  };

  if (returnLogin) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={2} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>
                  Nuevo Estado / Provincia
                </div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    name="name"
                    value={values.name}
                    error={values.errorName}
                    onChange={handleChange("name")}
                    fullWidth
                    required
                    label="Nombre"
                    margin="dense"
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <LoadCountries
                  value={values.countriesData}
                  refresh={changeCountries}
                  error={values.errorCountries}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <br></br>
                <br></br>
                <center>
                  <Button
                    type="submit"
                    className={classes.buttonSubmit}
                    onClick={submitForm}
                  >
                    Guardar
                  </Button>
                </center>
              </Grid>
              <Grid item xs={12} sm={12}>
                <center>
                  {openAlertSuccess ? (
                    <Alert severity="success">{mensaje_success}</Alert>
                  ) : (
                    ""
                  )}
                </center>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
