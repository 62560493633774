import React, { createContext, useState } from "react";

const UrlServicesContext = createContext();
const { Provider, Consumer } = UrlServicesContext;

function UrlServicesProvider({ children }) {
  /* ### URL BASE ### */
  //const [urlBase] = useState("http://localhost:3000/");

  /* ### API ### */
  //const [urlServices] = useState("https://apidev.tuspagos.ec/");
  const [urlServices] = useState("https://api.tuspagos.ec/");
  //const [urlServices] = useState("http://192.168.0.2:4000/");

  /* ### IMAGEN ### */
  /* const [urlLocalMediaServices] = useState(
    "https://webdev.tuspagos.ec/files/servipagos-services/"
  );*/
  const [urlLocalMediaServices] = useState(
    "https://web.tuspagos.ec/files/servipagos-services/"
  );

  /* ### LOAD SERVICES KEYAPLICATION */
  const [keyAplication] = useState("OGMGDuYv3QRwtzUQOpge");

  return (
    <Provider
      value={{
        urlServices,
        urlLocalMediaServices,
        keyAplication,
        // urlBase,
      }}
    >
      {children}
    </Provider>
  );
}

export {
  UrlServicesProvider,
  Consumer as urlServicesConsumer,
  UrlServicesContext,
};
