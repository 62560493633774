/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import { Remove } from "@material-ui/icons";

// PAGES
import DashboardPage from "views/Dashboard/Dashboard.js";
import AcounntsListAdmin from "views/Admin/AcounntsListAdmin.js";
import CompanyListAdmin from "views/Admin/CompanyListAdmin.js";
import TransactionsListAdmin from "views/Admin/TransactionsListAdmin.js";
import MyAccount from "views/Admin/MyAccount.js";
import GendersListAdmin from "views/Admin/GendersListAdmin.js";
import ProfilesListAdmin from "views/Admin/ProfilesListAdmin.js";
import CountriesListAdmin from "views/Admin/CountriesListAdmin.js";
import StatesListAdmin from "views/Admin/StatesListAdmin.js";
import CitiesListAdmin from "views/Admin/CitiesListAdmin.js";
import PaymentMethodsListAdmin from "views/Admin/PaymentMethodsListAdmin.js";

const dashboardRoutes = [
  {
    id: 0,
    subId: "",
    path: "/dashboard",
    name: "Inicio",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: DashboardPage,
    layout: "/superadmin",
    submenu: [],
  },
  {
    id: 0,
    subId: "",
    path: "/acounntslistadmin",
    name: "Cuentas",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: AcounntsListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    id: 0,
    subId: "",
    path: "/transactionslistadmin",
    name: "Transacciones",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: TransactionsListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    id: 0,
    subId: "",
    path: "/companylistadmin",
    name: "Servicios",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: CompanyListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    id: 1,
    subId: "",
    path: "",
    name: "Administración",
    rtlName: "",
    icon: Remove,
    component: "",
    layout: "/superadmin",
    submenu: [
      {
        subId: 1,
        name: "Comisiones",
        path: "/paymentmethodslist",
      },
      {
        subId: 1,
        name: "Género",
        path: "/genderslist",
      },
      {
        subId: 1,
        name: "Perfiles",
        path: "/profileslist",
      },
      {
        subId: 1,
        name: "Paises",
        path: "/countries",
      },
      {
        subId: 1,
        name: "Estados",
        path: "/states",
      },
      {
        subId: 1,
        name: "Ciudades",
        path: "/cities",
      },
    ],
  },
  {
    // SUBMENU COMMISSIONS list
    id: "",
    subId: 1,
    path: "/paymentmethodslist",
    name: "",
    rtlName: "",
    icon: "",
    component: PaymentMethodsListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU GENDERS list
    id: "",
    subId: 1,
    path: "/genderslist",
    name: "",
    rtlName: "",
    icon: "",
    component: GendersListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU Profiles list
    id: "",
    subId: 1,
    path: "/profileslist",
    name: "",
    rtlName: "",
    icon: "",
    component: ProfilesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU COUNTRIES list
    id: "",
    subId: 1,
    path: "/countries",
    name: "",
    rtlName: "",
    icon: "",
    component: CountriesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU STATES list
    id: "",
    subId: 1,
    path: "/states",
    name: "",
    rtlName: "",
    icon: "",
    component: StatesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    // SUBMENU CITIES list
    id: "",
    subId: 1,
    path: "/cities",
    name: "",
    rtlName: "",
    icon: "",
    component: CitiesListAdmin,
    layout: "/superadmin",
    submenu: [],
  },
  {
    id: 0,
    subId: "",
    path: "/myaccount",
    name: "Mi perfil",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: MyAccount,
    layout: "/superadmin",
    submenu: [],
  },
];

export default dashboardRoutes;
