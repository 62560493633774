import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Search, Cancel, Person, AddCircle } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import format from "date-fns/format";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";

import ViewAccounts from "views/dialog/ViewAccounts";
import DeleteAccounts from "views/dialog/DeleteAccounts";
import EnableAccounts from "views/dialog/EnableAccounts";
//import NewAcounts from "views/dialog/NewAcounts";
import EditAcounts from "views/dialog/EditAcounts";

import imgLoader from "assets/img/progress.gif";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function AcounntsListAdmin() {
  const classes = useStyles();
  const tableRef = React.createRef();
  const [returnLogin, setReturnLogin] = React.useState(false);

  const [rows, setRows] = useState([]);

  const [idRow, setIdRow] = useState("");
  const [openView, setopenView] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  //const [openNew, setopenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      /*{
        title: "*",
        field: "picture",
        filtering: false,
        render: (rowData) => (
          <img
            alt="avatar"
            src={
              rowData.picture === null
                ? img
                : urlLocalMediaProfile + rowData.picture
            }
            className={classes.imgUserProfileList}
          />
        ),
        width: "1%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          textAlign: "center",
        },
        headerStyle: { textAlign: "right" },
      },*/
      {
        title: "Nombre",
        field: "name",
        render: (rowData) => {
          return <Fragment>{rowData.name}</Fragment>;
        },
        width: "15%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          textAlign: "left",
          fontSize: 12,
        },
        headerStyle: { textAlign: "center" },
      },
      {
        title: "Apellido",
        field: "lastName",
        render: (rowData) => {
          return <Fragment>{rowData.lastName}</Fragment>;
        },
        width: "15%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          textAlign: "left",
          fontSize: 12,
        },
        headerStyle: { textAlign: "center" },
      },
      {
        title: "Email",
        field: "email",
        width: "25%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
      {
        title: "Perfil",
        field: "profileId",
        filtering: false,
        render: (rowData) => {
          return (
            <span>{rowData.profileId === 1 ? "Administrador" : "Usuario"}</span>
          );
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 11,
        },
      },
      {
        title: "Registro",
        filtering: false,
        render: (rowData) => format(new Date(rowData.createdAt), "dd-MM-yyyy"),
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
          textAlign: "center",
        },
      },
      {
        title: "Estado",
        field: "status",
        filtering: false,
        render: (rowData) => {
          const text =
            rowData.status === 1
              ? "ACTIVO"
              : rowData.status === 2
              ? "PENDIENTE "
              : "INACTIVO";
          const color =
            rowData.status === 1
              ? "green"
              : rowData.status === 2
              ? "orange "
              : "red";
          return <b style={{ color: color }}>{text}</b>;
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 11,
        },
      },
    ],
  });

  useEffect(() => {
    dataRows();
  }, []);

  const dataRows = () => {
    axios
      .get(`${urlServices}users`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRows(response.data);
          setLoader(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear();
            setReturnLogin(true);
          }, 200);
        } else if (e.response.status === 404) {
          setRows([]);
          setLoader(false);
        } else {
          setRows([]);
          setError(true);
          console.log(e);
        }
      });
  };

  const RefreshTable = () => {
    dataRows();
  };

  const handleCloseView = () => {
    setopenView(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  /* const handleCloseNewUser = () => {
    setopenNew(false);
  };*/

  const handleCloseOpenEdit = () => {
    setOpenEdit(false);
  };

  if (returnLogin) {
    return <Redirect to="/login" />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Person className={classes.iconWhite} /> Listado de registros de
              cuentas
            </div>
          </CardHeader>
          <CardBody>
            {/*<Grid container>
              <Grid item xs={12} sm={12}>
                <Button
                  className={classes.buttonRight}
                  onClick={(event) => {
                    setopenNew(true);
                  }}
                  startIcon={<AddCircle />}
                >
                  Agregar cuenta
                </Button>
              </Grid>
            </Grid>*/}
            {error ? (
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            ) : (
              ""
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      src={imgLoader}
                      alt="loader"
                      style={{
                        width: 50,
                        margin: "100px 0",
                        objectFit: "contain",
                      }}
                    />
                  </center>
                </Grid>
              </Grid>
            ) : (
              <MaterialTable
                title=""
                tableRef={tableRef}
                columns={state.columns}
                style={{
                  boxShadow: "unset",
                  border: "1px solid rgb(241, 241, 241)",
                }}
                data={rows}
                localization={{
                  pagination: {
                    labelRowsSelect: "Filas",
                    labelDisplayedRows: " {from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Previo",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Ultima página",
                  },
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: "Filtrar",
                    searchPlaceholder: "Buscar",
                  },
                  header: {
                    actions: " --- ",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos.",
                    filterRow: {
                      filterTooltip: "Filtro",
                    },
                  },
                }}
                actions={[
                  {
                    icon: "search",
                    tooltip: "Ver",
                    onClick: (event, rowData) => {
                      setopenView(true);
                      setIdRow(rowData);
                    },
                  },
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    onClick: (event, rowData) => {
                      setOpenEdit(true);
                      setIdRow(rowData);
                    },
                  },
                  (rowData1) => ({
                    icon: () => <Cancel />,
                    tooltip: "Deshabilitar",
                    onClick: (event, rowData) => {
                      setOpenDelete(true);
                      setIdRow(rowData);
                    },
                    hidden:
                      rowData1.id === 1
                        ? true
                        : rowData1.status === 1
                        ? false
                        : true,
                  }),
                  (rowData2) => ({
                    icon: () => <AddCircle style={{ color: "green" }} />,
                    tooltip: "Habilitar",
                    onClick: (event, rowData) => {
                      setOpenAdd(true);
                      setIdRow(rowData);
                    },
                    hidden:
                      rowData2.id === 1
                        ? true
                        : rowData2.status === 0 || rowData2.status === 99
                        ? false
                        : true,
                  }),
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () => RefreshTable,
                  },
                ]}
                icons={tableIcons}
                options={{
                  search: true,
                  padding: "default",
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: "#F2F2F2",
                    padding: "1%",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 12,
                  },
                  cellStyle: { border: "1px solid #DDDDDD" },
                }}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      {openView ? (
        <ViewAccounts open={openView} exit={handleCloseView} id={idRow} />
      ) : (
        ""
      )}
      {openDelete ? (
        <DeleteAccounts
          open={openDelete}
          exit={handleCloseDelete}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      {openAdd ? (
        <EnableAccounts
          open={openAdd}
          exit={handleCloseAdd}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}

      {/*openNew ? (
        <NewAcounts
          open={openNew}
          exit={handleCloseNewUser}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )*/}
      {openEdit ? (
        <EditAcounts
          open={openEdit}
          exit={handleCloseOpenEdit}
          callBackRefresh={RefreshTable}
          id={idRow}
        />
      ) : (
        ""
      )}
    </GridContainer>
  );
}
