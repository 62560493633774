import React, { useState, forwardRef, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Search, Assignment } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";

import imgLoader from "assets/img/progress.gif";
import noimage from "assets/img/noimage.jpeg";

import Edit from "views/dialog/EditServicesCompany";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function CompanyListAdmin() {
  const classes = useStyles();
  const tableRef = React.createRef();

  const [rows, setRows] = useState([]);
  const [idRow, setIdRow] = useState("");
  const [openEdit, setOpenEdit] = useState(false);

  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication, urlLocalMediaServices } =
    useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      {
        title: "Imagen",
        render: (rowData) => {
          return (
            <img
              alt="picture"
              src={
                rowData.picture !== null || rowData.picture !== ""
                  ? urlLocalMediaServices + rowData.picture
                  : noimage
              }
              style={{ width: 50 }}
            />
          );
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 9,
        },
      },
      {
        title: "Descripcion",
        field: "description",
        filtering: false,
        render: (rowData) => {
          return <b>{rowData.description}</b>;
        },
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          textAlign: "left",
          fontSize: 12,
        },
        headerStyle: { textAlign: "center" },
      },
      {
        title: "Categoría",
        render: (rowData) => {
          return <b>{rowData.category.name}</b>;
        },
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
      {
        title: "Código",
        field: "code",
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
      {
        title: "Nombre",
        field: "name",
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    dataRows();
  }, []);

  const dataRows = async () => {
    /*  const url =
      "https://concentrador.produbanco.ec:441/WCFEmpresasSVP/TransaccionSvp.svc/TransaccionSvp";
    
    const args = `<soapenv:Envelope xmlns:soapenv='http://schemas.xmlsoap.org/soap/envelope/' xmlns:ser='https://servipagos.bll.contract/'><soapenv:Header/><soapenv:Body><ser:ConsultaServicio><!--Optional:--><ser:xmlRequerimientoServicio><![CDATA[<RequerimientoServicios><Sesion><Usuario><nombre>SETUSPAGOS</nombre><clave>Tuspagos2023</clave></Usuario><Terminal><ip>200.63.104.98</ip><nombre>az1-ts2</nombre><logon>Nehiver Carrión A</logon><direccion>Quito</direccion></Terminal></Sesion></RequerimientoServicios>]]></ser:xmlRequerimientoServicio></ser:ConsultaServicio></soapenv:Body></soapenv:Envelope>`;
 */
    try {
      await axios
        .get(`${urlServices}services`, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          /*.get(url, args, {
        headers: {
          'Content-Type': 'application/xml',
          SOAPAction:
            "https://servipagos.bll.contract/SeguridadSrv1/ConsultarServicio",
        },
      })
      .then((response) => {*/
          setRows(response.data);
          setLoader(false);
          // console.log(response.data.data.Servicio);
        })
        .catch((e) => {
          if (e.response.status === 404) {
            // console.log("ENTRO", e.response);
            setRows([]);
            setLoader(false);
          } else {
            // console.log("ENTROOOOOOOO1", e.response);
            setRows([]);
            setError(true);
            setLoader(false);
            console.log(e);
          }
        });
    } catch (error) {
      // console.log("ENTROOOOOOOO2");
      setError(true);
      setRows([]);
      setLoader(false);
      console.error(error);
      throw error;
    }
  };

  const RefreshTable  = () => {
    dataRows();
  };

  const handleCloseOpenEdit = () => {
    setOpenEdit(false);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Assignment className={classes.iconWhite} /> Listado de servicios
            </div>
          </CardHeader>
          <CardBody>
            {error ? (
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            ) : (
              ""
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      src={imgLoader}
                      alt="loader"
                      style={{
                        width: 50,
                        margin: "100px 0",
                        objectFit: "contain",
                      }}
                    />
                  </center>
                </Grid>
              </Grid>
            ) : (
              <MaterialTable
                title=""
                tableRef={tableRef}
                columns={state.columns}
                style={{
                  boxShadow: "unset",
                  border: "1px solid rgb(241, 241, 241)",
                }}
                data={rows}
                localization={{
                  pagination: {
                    labelRowsSelect: "Filas",
                    labelDisplayedRows: " {from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Previo",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Ultima página",
                  },
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: "Filtrar",
                    searchPlaceholder: "Buscar",
                  },
                  header: {
                    actions: " --- ",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos.",
                    filterRow: {
                      filterTooltip: "Filtro",
                    },
                  },
                }}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    onClick: (event, rowData) => {
                      setOpenEdit(true);
                      setIdRow(rowData);
                    },
                  },
                ]}
                icons={tableIcons}
                options={{
                  search: true,
                  padding: "default",
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: "#F2F2F2",
                    padding: "1%",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 12,
                  },
                  cellStyle: { border: "1px solid #DDDDDD" },
                }}
              />
            )}
            {openEdit ? (
              <Edit
                open={openEdit}
                exit={handleCloseOpenEdit}
                callBackRefresh={RefreshTable}
                id={idRow}
              />
            ) : (
              ""
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
