import React, { Fragment, useEffect, useState, useContext } from "react";
import { Redirect } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import { UrlServicesContext } from "../UrlServicesContext";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function LoadStates(props) {
  const [resultsNew, setResultsNew] = useState([]);
  const [error, setError] = useState(false);
  const [isLoadedd, setIsLoadedd] = useState(false);
  const [returnLogin, setReturnLogin] = React.useState(false);

  let result;

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  useEffect(() => {
    const consultar = async () => {
      await axios
        .get(`${urlServices}states`, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          setIsLoadedd(true);

          if (!props.disabled) {
            result = response.data.filter(
              (filtered) =>
                Number(filtered.country.id) === Number(props.idCountries)
            );

            setResultsNew(result);
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear();
              setReturnLogin(true);
            }, 200);
          } else {
            setError(true);
            setIsLoadedd(false);
          }
        });
    };
    consultar();
  }, [urlServices, keyAplication, props.disabled, props.idCountries]);

  if (returnLogin) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      {error ? (
        <Alert severity="error">
          Error en la consulta de <b>PROVINCIAS</b>
        </Alert>
      ) : (
        ""
      )}
      {!isLoadedd ? (
        <Fragment>
          <Grid item xs={12} sm={12}>
            <center>
              <Box sx={{ width: "100%" }}>
                <Skeleton width="100%" height={35} />
              </Box>
            </center>
          </Grid>
        </Fragment>
      ) : (
        <Autocomplete
          id="combo-box-demo"
          options={resultsNew}
          value={props.value}
          onChange={(event, newValue) => {
            props.refresh(newValue);
          }}
          getOptionLabel={(option) => option.name || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Provincias"
              size={props.sizeInput}
              error={props.error}
              inputProps={{
                ...params.inputProps,
                autoComplete: "off", // disable autocomplete and autofill
              }}
            />
          )}
          disabled={props.disabled}
          classes={{
            clearIndicatorDirty: "none",
            popupIndicator: "none",
          }}
        />
      )}
    </Fragment>
  );
}
